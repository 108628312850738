import { forwardRef } from 'react'
import { cls } from '@/utils'

export interface SelectOption {
  value: string
  label: string
}

export interface SelectInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  leadignSelectOptions?: SelectOption[]
  leadingSelectClassname?: string
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  selectProps?: React.HTMLAttributes<HTMLSelectElement>
}

const SelectInput = forwardRef<HTMLDivElement, SelectInputProps>(
  ({ className, type, leadignSelectOptions, disabled, inputProps, selectProps }, ref) => {
    const showLeadingSelect = !!leadignSelectOptions

    return (
      <div
        ref={ref}
        className={cls(
          'flex px-4 py-2 gap-2 relative h-10 bg-surface',
          'group flex h-10 w-full rounded-md border border-input focus-within:border-border-active hover:focus-within:border-border-active focus-within:shadow-primary px-3 py-2 text-body-md ring-offset-background file:border-0 file:bg-transparent file:text-body-md file:font-medium placeholder:text-text-subdued focus-visible:outline-none group-focus-visible:ring-primary focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          disabled ? 'opacity-50 cursor-not-allowed' : '',
          className,
        )}
      >
        {showLeadingSelect ? (
          <select
            {...selectProps}
            className={cls(
              selectProps?.className,
              'border-0 bg-transparent focus-visible:border-0 focus-visible:outline-none',
            )}
          >
            {leadignSelectOptions.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </select>
        ) : null}
        <input
          type={type}
          disabled={disabled}
          autoFocus={false}
          {...inputProps}
          className={cls(
            'placeholder:text-text-subdued focus:border-0 flex-1 focus-visible:border-0 focus-visible:outline-none bg-surface',
            inputProps?.className,
          )}
        />
      </div>
    )
  },
)
SelectInput.displayName = 'SelectInput'

export default SelectInput
