import Button from '@/components/button'
import { useForm } from 'react-hook-form'
import { updateProfile } from '@/service/auth.service'
import { Form } from '@/components/ui/form'
import { useCallback, useEffect } from 'react'
import useAmplitude from '@/hooks/useAmplitude'
import { PersonaFormProps } from './_common'
import { cls, whisper } from '@/utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import SocialAccountForm, { socialMediaOptions } from '@/components/social-account-form'
import usePublicProfile from '@/hooks/usePublicProfile'
import { SELF_USER_ID } from '@/constants'

const formSchema = z.object({
  social_accounts: z
    .array(
      z.object({
        type: z.enum(socialMediaOptions.map((item) => item.value) as any),
        value: z.string().url().optional().or(z.literal('')),
      }),
    )
    .nonempty(),
})

export default function PersonaProfileForm({ onSubmit, onBack, value }: PersonaFormProps) {
  const { data: profile, isValidating: profileLoading } = usePublicProfile(SELF_USER_ID)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      social_accounts: [
        { type: 'x', value: '' },
        { type: 'instagram', value: '' },
      ],
    },
  })

  useEffect(() => {
    form.setValue(
      'social_accounts',
      profile?.social_accounts && Object.keys(profile.social_accounts).length > 0
        ? (Object.entries(profile.social_accounts)
            .map(([type, value]) => ({
              type,
              value,
            }))
            .filter((item) => socialMediaOptions.some((option) => option.value === item.type)) as any)
        : [
            { type: 'x', value: '' },
            { type: 'instagram', value: '' },
          ],
    )
  }, [form, profile])

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:profile')
  }, [track])

  const handleSkip = useCallback(() => {
    onSubmit({})
  }, [onSubmit])

  const handleSubmit = async () => {
    track('click:profile:update')

    const values = form.getValues()

    const isValid = await form.trigger()
    if (!isValid) {
      return
    }

    // check social accounts
    const socialAccounts = values.social_accounts
    const socialAccountsObj: Record<string, string> = {}
    let canSubmit = true
    socialAccounts.forEach((account, index) => {
      if (account.value) {
        if (socialAccountsObj[account.type]) {
          // set field error
          form.setError(['social_accounts', index, 'value'].join('.') as any, {
            type: 'manual',
            message: 'Duplicate social media type', // TODO: confirm error message
          })
          canSubmit = false
        } else {
          socialAccountsObj[account.type] = account.value
        }
      }
    })

    const submitData = {
      // ...profile
      social_accounts: socialAccountsObj,
    }
    if (canSubmit) {
      await updateProfile(submitData)
      onSubmit?.({})
    }
  }

  const inputClassName = cls(
    'text-body-md rounded-md bg-surface h-10 w-full border-2 border-border shadow-xs hover:border-border-hover',
  )

  return (
    <div className='size-full flex flex-col items-center justify-center'>
      <Form {...form}>
        <form
          className={cls('relative flex flex-col gap-4 w-full items-center')}
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <div className='w-full md:w-[480px] flex flex-col items-start justify-start h-[438px]'>
            <div className='w-full p-6 text-heading-lg font-bold tracking-45' aria-label='title'>
              Your social media?
              <span className='text-text-subdued pl-1'>(optional)</span>
            </div>
            <div className='w-full px-6'>
              <SocialAccountForm
                title={<span className='text-body-lg font-normal tracking-32'>Social media links</span>}
                form={form}
                fieldName='social_accounts'
                inputClassName={inputClassName}
              />
            </div>
            <div className='w-full p-6 pt-4 flex justify-between'>
              <Button variant='link' className='text-text' onClick={handleSkip}>
                Skip
              </Button>
              <Button
                variant='primary'
                className='rounded-md'
                type='submit'
                disabled={
                  !form.formState.isValid || !form.formState.isDirty || form.formState.isSubmitting || profileLoading
                }
              >
                Confirm
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  )
}
